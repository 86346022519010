interface Captcha {
  sessionKey: string;
  value: string;
  input: string | undefined;
}
interface Captchas {
  [key: string]: Captcha;
}

interface RefreshTokenResult {
  csrf: {
    param: string;
    token: string;
  };
  captchas: Captchas | undefined;
}

export default function init() {
  if (document.readyState === 'loading') {
    // Loading hasn't finished yet
    // Wait until the DOM is ready
    document.addEventListener('DOMContentLoaded', initCsrf);
  } else {
    initCsrf();
  }
}

function initCsrf() {
  // Fetch the form we want to deal with
  const forms = [...document.querySelectorAll<HTMLFormElement>('.fui-form')];
  if (forms) {
    forms.forEach((form) => {
      // Find the CSRF token hidden input, so we can replace it
      const formHandle = form.querySelector<HTMLInputElement>('input[name="handle"]')!;

      // Fetch the new tokens for the form and replace the captcha inputs
      fetch(`/actions/formie/forms/refresh-tokens?form=${formHandle.value}`)
      .then((result) => result.json())
      .then((result: RefreshTokenResult) => {
        // Update the CSRF token and captchas.
        // Use `csrf.input` for convenience
        const csrfInput = form.querySelector<HTMLInputElement>('input[name="CRAFT_CSRF_TOKEN"]')!;
          csrfInput.value = result.csrf.token;

          // Captchas - eg. Javascript captcha and duplicate capatcha
          if (result.captchas) {
            Object.values(result.captchas).forEach((captcha) => {
              if (!captcha.input) return; // Not all captchas have an input, eg. recaptcha
              const keyInput = form.querySelector<HTMLInputElement>(
                `input[name="${captcha.sessionKey}"]`,
              )!;
              keyInput.value = captcha.value;
            });
          }

          // Update the form's hash (if using Formie's themed JS)
          if (form.form && form.form.formTheme) {
            form.form.formTheme.updateFormHash();
          }
        });
    });
  }
}